<template>
    <div>
        <Navbar />
        <MainBanner />
        <Boxes />
        <SelectionCourses />
        <BuildSkillsOnline />
        <Funfacts />
        <PopularCourses />
        <Feedback />
        <MeetOurInstructors />
        <PotentialUnlimitedAccess />
        <Partner class="bg-f0f2f5 ptb-70" />
        <Blog heading="Check Out Our Latest Blog" blogLink="/blog-two" />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../DistanceLearning/MainBanner'
import Boxes from '../DistanceLearning/Boxes'
import SelectionCourses from '../DistanceLearning/SelectionCourses'
import BuildSkillsOnline from '../DistanceLearning/BuildSkillsOnline'
import Funfacts from '../DistanceLearning/Funfacts'
import PopularCourses from '../DistanceLearning/PopularCourses'
import Feedback from '../Common/Feedback'
import MeetOurInstructors from '../Common/MeetOurInstructors'
import PotentialUnlimitedAccess from '../Common/PotentialUnlimitedAccess'
import Blog from '../Common/Blog'
import Partner from '../Common/Partner'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'DistanceLearningPage',
    components: {
        Navbar,
        MainBanner,
        Boxes,
        SelectionCourses,
        BuildSkillsOnline,
        Funfacts,
        PopularCourses,
        Feedback,
        MeetOurInstructors,
        PotentialUnlimitedAccess,
        Partner,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>