<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Welcome to Vdemy" />
        <Authentication />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import Authentication from '../Authentication/Authentication'
import Footer from '../Layouts/Footer'

export default {
    name: 'AuthenticationPage',
    components: {
        Navbar,
        PageTitle,
        Authentication,
        Footer,
    },
}
</script>