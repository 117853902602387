<template>
    <div class="start-with-success-area pt-100 bg-f8f9f8 pb-70">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Education For Everyone</span>
                <h2>Start writing your own success story with a good beginning from Vdemy help</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img1.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>Sarah Taylor</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img2.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>Alex Maxwell</h3>
                            <span>Tutor</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img3.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>Eva Lucy</h3>
                            <span>UX/UI Designer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img4.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>Alina Smith</h3>
                            <span>Author</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img5.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>James Anderson</h3>
                            <span>Designer</span>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="start-with-success-box">
                        <div class="image">
                            <router-link to="/success-story">
                                <img src="../../assets/img/success-people/img6.jpg" alt="image">
                            </router-link>
                        </div>
                        <div class="content">
                            <router-link to="/success-story" class="link-btn">
                                <i class="flaticon-right"></i>
                            </router-link>
                            <h3>Meg Lanning</h3>
                            <span>Writer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'StartWithSuccess'
}
</script>