<template>
    <footer class="footer-area">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <router-link to="/" class="logo">
                            <img src="../../assets/img/logo2.png" alt="logo">
                        </router-link>
                        <p>Working to bring significant changes in online-based learning by doing extensive research for course curriculum preparation, student engagements, and looking forward to the flexible education!</p>
                        <ul class="social-link">
                            <li>
                                <a href="https://facebook.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-facebook'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://twitter.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-twitter'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://instagram.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-instagram'></i>
                                </a>
                            </li>
                            <li>
                                <a href="https://linkedin.com/" class="d-block" target="_blank">
                                    <i class='bx bxl-linkedin'></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget pl-5">
                        <h3>Explore</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/">Home</router-link></li>
                            <li><router-link to="/about-one">About</router-link></li>
                            <li><router-link to="/courses-one">Courses</router-link></li>
                            <li><router-link to="/events">Events</router-link></li>
                            <li><router-link to="/contact">Contact</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-2 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Resources</h3>
                        <ul class="footer-links-list">
                            <li><router-link to="/success-story">Student Success</router-link></li>
                            <li><router-link to="/blog-one">Blog</router-link></li>
                            <li><router-link to="/events">Events</router-link></li>
                            <li><router-link to="/purchase-guide">Go Premium</router-link></li>
                            <li><router-link to="/advisor">Team Plans</router-link></li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-footer-widget">
                        <h3>Address</h3>
                        <ul class="footer-contact-info">
                            <li>
                                <i class='bx bx-map'></i>
                                2750 Quadra Street Golden Victoria Road, New York, USA
                            </li>
                            <li>
                                <i class='bx bx-phone-call'></i>
                                <a href="tel:+44587154756">+1 (123) 456 7890</a>
                            </li>
                            <li>
                                <i class='bx bx-envelope'></i>
                                <a href="mailto:hello@vdemy.com">hello@vdemy.com</a>
                            </li>
                            <li>
                                <i class='bx bxs-inbox'></i>
                                <a href="tel:+557854578964">+55 785 4578964</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
            <div class="footer-bottom-area">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-6">
                        <p><i class='bx bx-copyright'></i>{{currentYear}} Vdemy is Proudly Powered by <a target="_blank" href="https://envytheme.com/">EnvyTheme</a></p>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <ul>
                            <li>
                                <router-link to="/privacy-policy">Privacy Policy</router-link>
                            </li>
                            <li>
                                <router-link to="/terms-of-service">Terms & Conditions</router-link>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="lines">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer',
    data() {
        return {
            currentYear: new Date().getFullYear(),
        };
    }
}
</script>