<template>
    <div>
        <Navbar />
        <MainBanner />
        <HealthierLifestyle />
        <ChooseYourProgram />
        <TrySomethingDifferent />
        <Lifestyle />
        <TopSellingCourses />
        <WeAreDifferent />
        <OurUpcomingEvents />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../HealthCoaching/MainBanner'
import HealthierLifestyle from '../HealthCoaching/HealthierLifestyle'
import ChooseYourProgram from '../HealthCoaching/ChooseYourProgram'
import TrySomethingDifferent from '../HealthCoaching/TrySomethingDifferent'
import Lifestyle from '../HealthCoaching/Lifestyle'
import TopSellingCourses from '../HealthCoaching/TopSellingCourses'
import WeAreDifferent from '../HealthCoaching/WeAreDifferent'
import OurUpcomingEvents from '../HealthCoaching/OurUpcomingEvents'
import Blog from '../HealthCoaching/Blog'
import Subscribe from '../HealthCoaching/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'HealthCoachingPage',
    components: {
        Navbar,
        MainBanner,
        HealthierLifestyle,
        ChooseYourProgram,
        TrySomethingDifferent,
        Lifestyle,
        TopSellingCourses,
        WeAreDifferent,
        OurUpcomingEvents,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>