<template>
    <div class="view-all-courses-area-three bg-fff8f8">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="view-all-courses-image-style-two">
                        <img src="../../assets/img/strategy.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="view-all-courses-content-style-two">
                        <span class="sub-title">DISTANCE LEARNING</span>
                        <h2>Get ahead with Learning Paths. Stay Sharp.</h2>
                        <p>With The Open University you can study whenever and wherever you choose. We have students in over 128 countries, and a global reputation as a pioneer in the field of flexible learning. Our flexible teaching also means, if you travel often or need to relocate, you can continue to study wherever you go.</p>
                        <router-link to="courses-one" class="default-btn">
                            <i class="flaticon-agenda"></i>
                            View Courses
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="bulb">
            <img src="../../assets/img/shape/bulb.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'LearningPaths'
}
</script>