<template>
    <div class="courses-area ptb-100">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Go At Your Own Pace</span>
                <h2>Top Selling Courses</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <router-link to="/single-courses-one" class="d-block image">
                                <img src="../../assets/img/courses/img1.jpg" alt="image">
                            </router-link>
                            <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$39</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="../../assets/img/user/user1.jpg" class="rounded-circle" alt="image">
                                <span>Alex Morgan</span>
                            </div>
                            <h3>
                                <router-link to="/single-courses-one">
                                    Online Course For Korean Level 1
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                <li><i class='flaticon-people'></i> 145 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <router-link to="/single-courses-one" class="d-block image">
                                <img src="../../assets/img/courses/img2.jpg" alt="image">
                            </router-link>
                            <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$49</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="../../assets/img/user/user2.jpg" class="rounded-circle" alt="image">
                                <span>Sarah Taylor</span>
                            </div>
                            <h3>
                                <router-link to="/single-courses-one">
                                    Online Course For Marathi Level 2
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                <li><i class='flaticon-people'></i> 100 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6">
                    <div class="single-courses-box">
                        <div class="courses-image">
                            <router-link to="/single-courses-one" class="d-block image">
                                <img src="../../assets/img/courses/img3.jpg" alt="image">
                            </router-link>
                            <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                            <div class="price shadow">$59</div>
                        </div>
                        <div class="courses-content">
                            <div class="course-author d-flex align-items-center">
                                <img src="../../assets/img/user/user3.jpg" class="rounded-circle" alt="image">
                                <span>David Warner</span>
                            </div>
                            <h3>
                                <router-link to="/single-courses-one">
                                    Online Course For French Level 3
                                </router-link>
                            </h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                            <ul class="courses-box-footer d-flex justify-content-between align-items-center">
                                <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                <li><i class='flaticon-people'></i> 150 Students</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="courses-info">
                        <p>Get the most dedicated consultation for your life-changing course. Earn a certification for your effort and passion 
                            <router-link to="/authentication">Join Free Now</router-link>.
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'TopSellingCourses'
}
</script>