<template>
    <div>
        <Navbar />
        <MainBanner />
        <Boxes />
        <DevelopYourSkills />
        <SelectionCourses />
        <Feedback />
        <TopCategories />
        <Funfacts />
        <YourClassesPhysically />
        <OurUpcomingEvents />
        <Subscribe />
        <PartnerTwo class="bg-fe4a55" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../ModernSchooling/MainBanner'
import Boxes from '../ModernSchooling/Boxes'
import DevelopYourSkills from '../ModernSchooling/DevelopYourSkills'
import SelectionCourses from '../ModernSchooling/SelectionCourses'
import Feedback from '../Common/Feedback'
import TopCategories from '../Common/TopCategories'
import Funfacts from '../Common/Funfacts'
import YourClassesPhysically from '../ModernSchooling/YourClassesPhysically'
import OurUpcomingEvents from '../ModernSchooling/OurUpcomingEvents'
import Subscribe from '../Common/Subscribe'
import PartnerTwo from '../Common/PartnerTwo'
import Footer from '../Layouts/Footer'

export default {
    name: 'ModernSchoolingPage',
    components: {
        Navbar,
        MainBanner,
        Boxes,
        DevelopYourSkills,
        SelectionCourses,
        Feedback,
        TopCategories,
        Funfacts,
        YourClassesPhysically,
        OurUpcomingEvents,
        Subscribe,
        PartnerTwo,
        Footer,
    }
}
</script>