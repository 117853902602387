<template>
    <div class="funfacts-area bg-fffaf3">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-3 col-sm-6">
                    <div class="single-funfacts-item">
                        <h3>1926</h3>
                        <p>Finished Sessions</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6">
                    <div class="single-funfacts-item">
                        <h3>3279</h3>
                        <p>Enrolled Learners</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6">
                    <div class="single-funfacts-item">
                        <h3>250</h3>
                        <p>Online Instructors</p>
                    </div>
                </div>
                <div class="col-lg-3 col-md-3 col-sm-6">
                    <div class="single-funfacts-item">
                        <h3>100%</h3>
                        <p>Satisfaction Rate</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Funfacts'
}
</script>