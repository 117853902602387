<template>
    <div class="courses-area pt-100 pb-70 bg-f5f7fa">
        <div class="container">
            <div class="section-title">
                <span class="sub-title">Welcome to Vdemy</span>
                <h2>Our Language Courses</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
            </div>
            <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-language-courses-box">
                        <img src="../../assets/img/language-courses/img1.png" alt="image">
                        <h3>Chinese</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/courses-one" class="default-btn">
                            <i class="flaticon-right"></i>
                            View More
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-language-courses-box">
                        <img src="../../assets/img/language-courses/img2.png" alt="image">
                        <h3>Spanish</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/courses-one" class="default-btn">
                            <i class="flaticon-right"></i>
                            View More
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-language-courses-box">
                        <img src="../../assets/img/language-courses/img3.png" alt="image">
                        <h3>Japanese</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore.</p>
                        <router-link to="/courses-one" class="default-btn">
                            <i class="flaticon-right"></i>
                            View More
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'OurLanguageCourses'
}
</script>