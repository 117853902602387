<template>
    <div>
        <Navbar />
        <MainBanner />
        <AboutUs />
        <BestServicesKids />
        <TopSellingCourses />
        <Feedback />
        <KidsAges />
        <OurUpcomingEvents />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../Kindergaten/MainBanner'
import AboutUs from '../Kindergaten/AboutUs'
import BestServicesKids from '../Kindergaten/BestServicesKids'
import TopSellingCourses from '../Kindergaten/TopSellingCourses'
import Feedback from '../Kindergaten/Feedback'
import KidsAges from '../Kindergaten/KidsAges'
import OurUpcomingEvents from '../Kindergaten/OurUpcomingEvents'
import Blog from '../Kindergaten/Blog'
import Subscribe from '../Kindergaten/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'KindergartenPage',
    components: {
        Navbar,
        MainBanner,
        AboutUs,
        BestServicesKids,
        TopSellingCourses,
        Feedback,
        KidsAges,
        OurUpcomingEvents,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>