<template>
    <div>
        <Navbar />
        <MainBanner />
        <OnlineYogaTraining />
        <YourClassesPhysically />
        <YogaTeacher />
        <Feedback />
        <OnlineYogaCourses />
        <Funfacts />
        <DownloadCoursesSyllabus />
        <PricingPlan />
        <Blog />
        <Subscribe />
        <PartnerTwo class="bg-6ba292" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../YogaTraining/MainBanner'
import OnlineYogaTraining from '../YogaTraining/OnlineYogaTraining'
import YourClassesPhysically from '../YogaTraining/YourClassesPhysically'
import YogaTeacher from '../YogaTraining/YogaTeacher'
import Feedback from '../YogaTraining/Feedback'
import OnlineYogaCourses from '../YogaTraining/OnlineYogaCourses'
import Funfacts from '../Common/Funfacts'
import DownloadCoursesSyllabus from '../YogaTraining/DownloadCoursesSyllabus'
import PricingPlan from '../YogaTraining/PricingPlan'
import Blog from '../YogaTraining/Blog'
import Subscribe from '../YogaTraining/Subscribe'
import PartnerTwo from '../Common/PartnerTwo'
import Footer from '../Layouts/Footer'

export default {
    name: 'YogaTrainingPage',
    components: {
        Navbar,
        MainBanner,
        OnlineYogaTraining,
        YourClassesPhysically,
        YogaTeacher,
        Feedback,
        OnlineYogaCourses,
        Funfacts,
        DownloadCoursesSyllabus,
        PricingPlan,
        Blog,
        Subscribe,
        PartnerTwo,
        Footer,
    }
}
</script>