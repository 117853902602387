<template>
    <div class="main-banner-wrapper">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="main-banner-wrapper-content">
                        <h1>Achieve the Best Results with Academy</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/authentication" class="default-btn">
                            <i class="flaticon-user"></i>
                            Join For Free
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="main-banner-wrapper-image text-center">
                        <img src="../../assets/img/banner/banner-img3.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape14">
            <img src="../../assets/img/shape/banner-shape15.png" alt="image">
        </div>
        <div class="banner-shape15">
            <img src="../../assets/img/shape/banner-shape16.png" alt="image">
        </div>
        <div class="banner-shape16">
            <img src="../../assets/img/shape/banner-shape17.png" alt="image">
        </div>
        <div class="banner-shape17">
            <img src="../../assets/img/shape/banner-shape18.png" alt="image">
        </div>
        <div class="banner-shape18">
            <img src="../../assets/img/shape/banner-shape19.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>