<template>
    <div class="banner-wrapper">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="banner-wrapper-text">
                        <h1>Build Skills With Experts Any Time, Anywhere</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/courses-four" class="default-btn">
                            <i class="flaticon-user"></i>V
                            iew All Courses
                            <span></span>
                        </router-link>
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape11">
            <img src="../../assets/img/shape/banner-shape12.png" alt="image">
        </div>
        <div class="banner-shape12">
            <img src="../../assets/img/shape/banner-shape13.png" alt="image">
        </div>
        <div class="banner-shape13">
            <img src="../../assets/img/shape/banner-shape14.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>