<template>
    <div class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <div class="row">
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img3.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-sm-6 col-md-6 col-6">
                                <div class="image">
                                    <img src="../../assets/img/about/about-img4.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <span class="sub-title">Distance learning</span>
                        <h2>89% of people learning for professional development report career benefits like getting a promotion, starting a new career</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <ul class="features-list">
                            <li><span><i class="flaticon-experience"></i> Expert Instruction</span></li>
                            <li><span><i class="flaticon-time-left"></i> Lifetime Access</span></li>
                            <li><span><i class="flaticon-tutorials"></i> Remote Learning</span></li>
                            <li><span><i class="flaticon-self-growth"></i> Self Development</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
        <div class="shape1">
            <img src="../../assets/img/shape/shape1.png" alt="image">
        </div>
        <div class="shape2">
            <img src="../../assets/img/shape/shape2.png" alt="image">
        </div>
        <div class="shape3">
            <img src="../../assets/img/shape/shape3.png" alt="image">
        </div>
        <div class="shape4">
            <img src="../../assets/img/shape/shape4.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'StartingNewCareer'
}
</script>