<template>
    <div class="banner-section">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-5 col-md-12">
                    <div class="banner-content">
                        <h1>Project Management Certification Training </h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <router-link to="/authentication" class="default-btn">
                            <i class="flaticon-user"></i>
                            Join For Free
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-7 col-md-12">
                    <div class="banner-image">
                        <img src="../../assets/img/banner/banner-img1.png" alt="image">
                        <div class="banner-shape4">
                            <img src="../../assets/img/shape/banner-shape4.png" alt="image">
                        </div>
                        <div class="banner-shape5">
                            <img src="../../assets/img/shape/banner-shape5.png" alt="image">
                        </div>
                        <div class="banner-shape6">
                            <img src="../../assets/img/shape/banner-shape6.png" alt="image">
                        </div>
                        <div class="banner-shape7">
                            <img src="../../assets/img/shape/banner-shape7.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>