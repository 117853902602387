<template>
    <div>
        <Navbar />
        <MainBanner />
        <Featured />
        <WhyChooseUs />
        <TopSellingCourses />
        <Funfacts />
        <Feedback />
        <ExpertCoaches />
        <AppDownload />
        <Blog />
        <FitnessCoaching />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../GymCoaching/MainBanner'
import Featured from '../GymCoaching/Featured'
import WhyChooseUs from '../GymCoaching/WhyChooseUs'
import TopSellingCourses from '../GymCoaching/TopSellingCourses'
import Funfacts from '../GymCoaching/Funfacts'
import Feedback from '../GymCoaching/Feedback'
import ExpertCoaches from '../GymCoaching/ExpertCoaches'
import AppDownload from '../GymCoaching/AppDownload'
import Blog from '../GymCoaching/Blog'
import FitnessCoaching from '../GymCoaching/FitnessCoaching'
import Footer from '../Layouts/Footer'

export default {
    name: 'GymCoachingPage',
    components: {
        Navbar,
        MainBanner,
        Featured,
        WhyChooseUs,
        TopSellingCourses,
        Funfacts,
        Feedback,
        ExpertCoaches,
        AppDownload,
        Blog,
        FitnessCoaching,
        Footer,
    }
}
</script>