<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
        <TopSellingCourses />
        <DevelopYourSkills />
        <Testimonials />
        <SelfDevelopmentCourse />
        <Partner />
        <Video />
        <Funfacts />
        <Blog />
        <LearningPaths />
        <UnlimitedAccess />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../LearningManagement/MainBanner'
import Features from '../LearningManagement/Features'
import TopSellingCourses from '../LearningManagement/TopSellingCourses'
import DevelopYourSkills from '../LearningManagement/DevelopYourSkills'
import Testimonials from '../LearningManagement/Testimonials'
import SelfDevelopmentCourse from '../LearningManagement/SelfDevelopmentCourse'
import Partner from '../LearningManagement/Partner'
import Video from '../LearningManagement/Video'
import Funfacts from '../LearningManagement/Funfacts'
import Blog from '../LearningManagement/Blog'
import LearningPaths from '../LearningManagement/LearningPaths'
import UnlimitedAccess from '../LearningManagement/UnlimitedAccess'
import Footer from '../Layouts/Footer'

export default {
    name: 'LearningManagementPage',
    components: {
        Navbar,
        MainBanner,
        Features,
        TopSellingCourses,
        DevelopYourSkills,
        Testimonials,
        SelfDevelopmentCourse,
        Partner,
        Video,
        Funfacts,
        Blog,
        LearningPaths,
        UnlimitedAccess,
        Footer,
    }
}
</script>