<template>
    <div class="main-banner-area">
        <div class="container-fluid">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-content-style-two">
                        <h1>The World's Leading Distance-Learning Provider</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse.</p>
                        <router-link to="/authentication" class="default-btn">
                            <i class="flaticon-user"></i>
                            Join For Free
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="main-banner-image-style-two">
                        <img src="../../assets/img/banner/banner-img4.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="banner-shape1">
            <img src="../../assets/img/shape/banner-shape1.png" alt="image">
        </div>
        <div class="banner-shape2">
            <img src="../../assets/img/shape/banner-shape2.png" alt="image">
        </div>
        <div class="banner-shape3">
            <img src="../../assets/img/shape/banner-shape3.png" alt="image">
        </div>
        <div class="bulb">
            <img src="../../assets/img/shape/bulb.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>