<template>
    <div class="partner-area ptb-70">
        <div class="container">
            <div class="row align-items-center justify-content-center">
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img7.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img8.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img9.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img10.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img11.png" alt="image">
                    </div>
                </div>
                <div class="col-lg-2 col-6 col-sm-3 col-md-3">
                    <div class="single-partner-item">
                        <img src="../../assets/img/partner/img12.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'PartnerTwo'
}
</script>