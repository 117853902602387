<template>
    <div>
        <Navbar />
        <PageTitle pageTitle="Become A Teacher" />
        <PotentialUnlimitedAccess />
        <Funfacts />
        <ApplyInstructor />
        <TeacherRegister />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import PageTitle from '../Common/PageTitle'
import PotentialUnlimitedAccess from '../BecomeTeacher/PotentialUnlimitedAccess'
import Funfacts from '../BecomeTeacher/Funfacts'
import ApplyInstructor from '../BecomeTeacher/ApplyInstructor'
import TeacherRegister from '../BecomeTeacher/TeacherRegister'
import Footer from '../Layouts/Footer'

export default {
    name: 'BecomeTeacherPage',
    components: {
        Navbar,
        PageTitle,
        PotentialUnlimitedAccess,
        Funfacts,
        ApplyInstructor,
        TeacherRegister,
        Footer,
    },
}
</script>