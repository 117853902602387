<template>
    <div class="events-area bg-f9f9f9 ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="coaching-events-content">
                        <span class="sub-title">COACHING PROGRAM</span>
                        <h2 class="inter-font">My Upcoming Events</h2>
                        <div class="single-coaching-events-box">
                            <span class="location"><i class='bx bx-map'></i> New York</span>
                            <h3 class="inter-font"><a href="#">Fundamentals of Self Leadership</a></h3>
                            <router-link to="/authentication" class="default-btn">
                                <i class="flaticon-user"></i> 
                                Buy Ticket
                                <span></span>
                            </router-link>
                            <div class="date">
                                <div class="div">
                                    18
                                    <span>March</span>
                                </div>
                            </div>
                        </div>
                        <div class="single-coaching-events-box">
                            <span class="location"><i class='bx bx-map'></i> Washington DC</span>
                            <h3 class="inter-font"><a href="#">Network of Professional</a></h3>
                            <router-link to="/authentication" class="default-btn">
                                <i class="flaticon-user"></i> 
                                Buy Ticket
                                <span></span>
                            </router-link>
                            <div class="date">
                                <div class="div">
                                    25
                                    <span>April</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="coaching-events-image">
                        <img src="../../assets/img/business-coach/business-coach9.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
        <div class="c-shape5">
            <img src="../../assets/img/business-coach/c-shape5.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MyUpcomingEvents'
}
</script>