<template>
    <div class="boxes-area bg-f5f7fa">
        <div class="container">
            <div class="row justify-content-center">
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-box-item">
                        <div class="icon">
                            <i class="flaticon-brain-process"></i>
                            <img src="../../assets/img/shape/icon-shape.png" alt="image">
                        </div>
                        <h3>Learn the Latest Skills</h3>
                        <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                        <router-link to="/membership-levels" class="link-btn">Start Now!</router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-sm-6 col-md-6">
                    <div class="single-box-item">
                        <div class="icon">
                            <i class="flaticon-computer"></i>
                            <img src="../../assets/img/shape/icon-shape.png" alt="image">
                        </div>
                        <h3>Go at Your Own Pace</h3>
                        <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                        <router-link to="/membership-levels" class="link-btn">Start Now!</router-link>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-box-item">
                        <div class="icon">
                            <i class="flaticon-shield-1"></i>
                            <img src="../../assets/img/shape/icon-shape.png" alt="image">
                        </div>
                        <h3>Learn from Industry Experts</h3>
                        <p>Lorem ipsum dolor sit amet, consecteur adipiscing elit, sed do eiusmod tempor.</p>
                        <router-link to="/membership-levels" class="link-btn">Start Now!</router-link>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Boxes'
}
</script>