<template>
    <div class="testimonials-area ptb-100 bg-fef8ef">
        <div class="container">
            <div class="single-testimonials-box">
                <img src="../../assets/img/user/user1.jpg" class="client-img" alt="image">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum  ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed tempor incididunt ut labore et dolore.</p>
                <h3>John Smith</h3>
                <span>Python Developer</span>
                <div class="shape-img">
                    <img src="../../assets/img/shape/shape4.png" class="shape-1" alt="image">
                    <img src="../../assets/img/shape/shape14.png" class="shape-2" alt="image">
                    <img src="../../assets/img/shape/shape7.png" class="shape-3" alt="image">
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Testimonials'
}
</script>