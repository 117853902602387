<template>
    <div>
        <Navbar />
        <MainBanner />
        <Partner class="pt-100 pb-70 border-bottom" />
        <EducationForEveryone />
        <OnlineLearning />
        <PopularCourses />
        <DistanceLearning />
        <SelfDevelopmentCourse />
        <Blog heading="Our Latest Publications" blogLink="/blog-one" />
        <ClassesPhysically />
        <AffordableCertification />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../eLearningSchool/MainBanner'
import Partner from '../Common/Partner'
import EducationForEveryone from '../eLearningSchool/EducationForEveryone'
import OnlineLearning from '../eLearningSchool/OnlineLearning'
import PopularCourses from '../eLearningSchool/PopularCourses'
import DistanceLearning from '../eLearningSchool/DistanceLearning'
import SelfDevelopmentCourse from '../eLearningSchool/SelfDevelopmentCourse'
import Blog from '../Common/Blog'
import ClassesPhysically from '../eLearningSchool/ClassesPhysically'
import AffordableCertification from '../eLearningSchool/AffordableCertification'
import Footer from '../Layouts/Footer'

export default {
    name: 'eLearningSchoolPage',
    components: {
        Navbar,
        MainBanner,
        Partner,
        EducationForEveryone,
        OnlineLearning,
        PopularCourses,
        DistanceLearning,
        SelfDevelopmentCourse,
        Blog,
        ClassesPhysically,
        AffordableCertification,
        Footer,
    }
}
</script>