<template>
    <div class="information-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="information-content">
                        <span class="sub-title">Information</span>
                        <h2>How To Apply?</h2>
                        <ul class="apply-details">
                            <li>
                                <div class="icon">
                                    <i class="flaticon-checkmark"></i>
                                </div>
                                <h3>Select Suitable Course</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-webinar"></i>
                                </div>
                                <h3>Student Information</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-credit-card-1"></i>
                                </div>
                                <h3>Payment Information</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </li>
                            <li>
                                <div class="icon">
                                    <i class="flaticon-verify"></i>
                                </div>
                                <h3>Register Now</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore.</p>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="information-image text-center">
                        <img src="../../assets/img/information-img.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'HowToApply'
}
</script>