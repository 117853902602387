<template>
    <div class="premium-access-area-two">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="premium-access-content-style-two">
                        <span class="sub-title">GO AT YOUR OWN PACE</span>
                        <h2>Give Their Limitless Potential Unlimited Access</h2>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/membership-levels" class="default-btn">
                            <i class="flaticon-user"></i>
                            Get Started Now
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="premium-access-image-style-two">
                        <img src="../../assets/img/security.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UnlimitedAccess'
}
</script>