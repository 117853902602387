<template>
    <div class="events-area bg-shape bg-eee8df">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Events</span>
                    <h2 class="font-weight-black">Our Upcoming Events</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-5 p-0">
                                    <div class="image bg1">
                                        <img src="../../assets/img/events/kindergarten-img1.jpg" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <img src="../../assets/img/shape/rectangle1.png" alt="image">
                                            <span>Wed, 03 June, 2022</span>
                                        </div>
                                        <h3 class="font-weight-black">
                                            <router-link to="/single-events">Music Conference</router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map"></i>Vancover, Canada</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-5 p-0">
                                    <div class="image bg2">
                                        <img src="../../assets/img/events/kindergarten-img2.jpg" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <img src="../../assets/img/shape/rectangle2.png" alt="image">
                                            <span>Thu, 04 June, 2022</span>
                                        </div>
                                        <h3 class="font-weight-black">
                                            <router-link to="/single-events">Paper Plates Art</router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map"></i>Sydney, Australia</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-5 p-0">
                                    <div class="image bg3">
                                        <img src="../../assets/img/events/kindergarten-img3.jpg" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <img src="../../assets/img/shape/rectangle3.png" alt="image">
                                            <span>Fri, 05 June, 2022</span>
                                        </div>
                                        <h3 class="font-weight-black">
                                            <router-link to="/single-events">Imagination Classes</router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map"></i>Istanbul, Turkey</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12 col-sm-6">
                        <div class="events-box">
                            <div class="row m-0">
                                <div class="col-lg-4 col-md-5 p-0">
                                    <div class="image bg4">
                                        <img src="../../assets/img/events/kindergarten-img4.jpg" alt="image">
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-7 p-0">
                                    <div class="content">
                                        <div class="date">
                                            <img src="../../assets/img/shape/rectangle4.png" alt="image">
                                            <span>Sat, 06 June, 2022</span>
                                        </div>
                                        <h3 class="font-weight-black">
                                            <router-link to="/single-events">Number Matching</router-link>
                                        </h3>
                                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do.</p>
                                        <span class="location"><i class="bx bx-map"></i>New York, USA</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="kindergarten-shape17">
                <img src="../../assets/img/kindergarten-shape/k-shape17.png" alt="image">
            </div>
            <div class="kindergarten-shape18">
                <img src="../../assets/img/kindergarten-shape/k-shape18.png" alt="image">
            </div>
        </div>
</template>

<script>
export default {
    name: 'OurUpcomingEvents'
}
</script>