<template>
    <div class="kindergarten-main-banner">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="kindergarten-banner-content">
                        <div class="image">
                            <img src="../../assets/img/shape/bird1.png" alt="image">
                        </div>
                        <h1>A lifetime of <span>Confidence</span> starts here</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        <router-link to="/authentication" class="default-btn-style-two">
                            <i class="flaticon-user"></i>
                            Join For Free
                        </router-link>
                        <div class="circle-shape">
                            <img src="../../assets/img/shape/circle.png" alt="image">
                        </div>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="kindergarten-banner-image">
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="image">
                                    <img src="../../assets/img/kindergarten/kids1.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="image">
                                    <img src="../../assets/img/kindergarten/kids2.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="image">
                                    <img src="../../assets/img/kindergarten/kids3.png" alt="image">
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6 col-sm-6">
                                <div class="image text-start">
                                    <img src="../../assets/img/kindergarten/kids4.png" class="mt-4" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="kindergarten-shape1">
            <img src="../../assets/img/kindergarten-shape/k-shape1.png" alt="image">
        </div>
        <div class="kindergarten-shape2">
            <img src="../../assets/img/kindergarten-shape/k-shape2.png" alt="image">
        </div>
        <div class="kindergarten-shape3">
            <img src="../../assets/img/kindergarten-shape/k-shape3.png" alt="image">
        </div>
        <div class="kindergarten-shape4">
            <img src="../../assets/img/kindergarten-shape/k-shape4.png" alt="image">
        </div>
        <div class="kindergarten-shape5">
            <img src="../../assets/img/kindergarten-shape/k-shape5.png" alt="image">
        </div>
        <div class="kindergarten-shape6">
            <img src="../../assets/img/kindergarten-shape/k-shape6.png" alt="image">
        </div>
    </div>
</template>

<script>
export default {
    name: 'MainBanner'
}
</script>