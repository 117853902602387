<template>
    <div class="courses-area ptb-100">
            <div class="container">
                <div class="section-title">
                    <span class="sub-title">Go at your own pace</span>
                    <h2>The World’s Largest Selection Of Courses</h2>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                </div>
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img1.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$39</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                Agile Crash Course: Agile Project Management
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 15 Lessons</li>
                                            <li><i class='flaticon-people'></i> 145 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img2.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$99</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                Vue JS 3 - The Complete Guide (incl. Vue Router & Vuex)
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                            <li><i class='flaticon-people'></i> 100 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img3.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$49</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                The Python Bible™ | Everything You Need to Program in Python
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 11 Lessons</li>
                                            <li><i class='flaticon-people'></i> 104 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img4.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$79</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                Mathematical Foundation For Machine Learning and AI
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 14 Lessons</li>
                                            <li><i class='flaticon-people'></i> 100 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img5.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$59</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                The Ultimate Drawing Course - Beginner to Advanced
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 09 Lessons</li>
                                            <li><i class='flaticon-people'></i> 150 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-12">
                        <div class="single-courses-item without-box-shadow">
                            <div class="row align-items-center">
                                <div class="col-lg-4 col-md-4">
                                    <div class="courses-image">
                                        <img src="../../assets/img/courses-small/img6.jpg" alt="image">
                                        <router-link to="/single-courses-one" class="link-btn"></router-link>
                                    </div>
                                </div>
                                <div class="col-lg-8 col-md-8">
                                    <div class="courses-content">
                                        <a href="#" class="fav"><i class="flaticon-heart"></i></a>
                                        <span class="price">$89</span>
                                        <h3>
                                            <router-link to="/single-courses-one">
                                                PyTorch: Deep Learning and Artificial Intelligence
                                            </router-link>
                                        </h3>
                                        <ul class="courses-content-footer d-flex justify-content-between align-items-center">
                                            <li><i class='flaticon-agenda'></i> 20 Lessons</li>
                                            <li><i class='flaticon-people'></i> 200 Students</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-12 col-md-12">
                        <div class="courses-info">
                            <router-link to="/courses-one" class="default-btn">
                                <i class="flaticon-user"></i>
                                View All Courses
                                <span></span>
                            </router-link>
                            <p>Get into details now? 
                                <router-link to="/courses-one">
                                    PM Master’s Program
                                </router-link>
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
</template>

<script>
export default {
    name: 'SelectionCourses'
}
</script>