<template>
    <div class="program-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="program-section-title">
                        <h2 class="playfair-display-font">Choose Your Program Bellow to See How We Can Help</h2>
                        <p>eCademy training programs can bring you a super exciting experience of learning through online! You never face any negative experience while enjoying your classes virtually by sitting in your comfort zone. Our flexible learning initiatives will help you to learn better and quicker than the traditional ways of learning skills.</p>
                        <router-link to="/authentication" class="default-btn">
                            <i class="flaticon-user"></i>
                            Get Started Now
                            <span></span>
                        </router-link>
                    </div>
                </div>
                <div class="col-lg-6 col-md-12">
                    <div class="program-list">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-program-box">
                                    <div class="shape"><img src="../../assets/img/shape/list-shape1.png" alt="image"></div>
                                    <div class="icon">
                                        <i class="flaticon-diet"></i>
                                    </div>
                                    <h3 class="playfair-display-font">
                                        <router-link to="/courses-one">Certification Courses</router-link>
                                    </h3>
                                    <p>Lorem ipsum dolor sit amet ut, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <router-link to="/single-courses-one" class="link-btn">Read More</router-link>
                                </div>
                                <div class="single-program-box">
                                    <div class="shape"><img src="../../assets/img/shape/list-shape2.png" alt="image"></div>
                                    <div class="icon">
                                        <i class="flaticon-healthy-food"></i>
                                    </div>
                                    <h3 class="playfair-display-font"><router-link to="/courses-one">Mens’s Coaching</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet ut, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <router-link to="/single-courses-one" class="link-btn">Read More</router-link>
                                </div>
                            </div>
                            <div class="col-lg-6 col-md-6">
                                <div class="single-program-box">
                                    <div class="shape"><img src="../../assets/img/shape/list-shape3.png" alt="image"></div>
                                    <div class="icon">
                                        <i class="flaticon-pineapple"></i>
                                    </div>
                                    <h3 class="playfair-display-font"><router-link to="/courses-one">Women’s Coaching</router-link></h3>
                                    <p>Lorem ipsum dolor sit amet ut, adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                    <router-link to="/single-courses-one" class="link-btn">Read More</router-link>
                                </div>
                            </div>
                        </div>
                        <div class="program-circle-shape">
                            <img src="../../assets/img/shape/circle-shape.png" alt="image">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ChooseYourProgram'
}
</script>