<template>
    <div class="app-download-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-4 col-md-12">
                    <div class="app-download-content">
                        <span class="sub-title">GET START AT EVERYWHERE</span>
                        <h2>Vdemy APP MORE EASIER YOUR LIFE</h2>
                        <p>We understand better that online-based learning can make a significant change to reach students from all over the world! Giving options to learn better always can offer the best outcomes!</p>
                        <a href="#" class="default-btn">
                            <i class="flaticon-user"></i>
                            Download The App
                            <span></span>
                        </a>
                    </div>
                </div>
                <div class="col-lg-8 col-md-12">
                    <div class="app-download-image">
                        <img src="../../assets/img/app.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'AppDownload'
}
</script>