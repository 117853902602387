<template>
    <div class="trainer-area pt-100 pb-70">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img1.jpg" alt="image">
                        <span class="designation">AERIAL EXPERT</span>
                        <div class="content">
                            <h3>ALYSA MAYA</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img2.jpg" alt="image">
                        <span class="designation">BARRE EXPERT</span>
                        <div class="content">
                            <h3>SARAH TAYLOR</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img3.jpg" alt="image">
                        <span class="designation">BOOTCAMP EXPERT</span>
                        <div class="content">
                            <h3>JAMES ANDY</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img4.jpg" alt="image">
                        <span class="designation">CROSSFIT EXPERT</span>
                        <div class="content">
                            <h3>LINA LUCY</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img5.jpg" alt="image">
                        <span class="designation">GYM EXPERT</span>
                        <div class="content">
                            <h3>ALINA STIVE</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-6">
                    <div class="single-trainer-box">
                        <img src="../../assets/img/trainer/trainer-img1.jpg" alt="image">
                        <span class="designation">AERIAL EXPERT</span>
                        <div class="content">
                            <h3>ALYSA MAYA</h3>
                            <ul class="social-link">
                                <li>
                                    <a href="https://facebook.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-facebook'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://twitter.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-twitter'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://instagram.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-instagram'></i>
                                    </a>
                                </li>
                                <li>
                                    <a href="https://linkedin.com/" class="d-block" target="_blank">
                                        <i class='bx bxl-linkedin'></i>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Trainer'
}
</script>