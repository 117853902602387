<template>
    <div>
        <Navbar />
        <MainBanner />
        <Features />
        <AboutMe />
        <CoachingTypes />
        <Funfacts />
        <Feedback />
        <TopSellingCourses />
        <MyUpcomingEvents />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../BusinessCoach/MainBanner'
import Features from '../BusinessCoach/Features'
import AboutMe from '../BusinessCoach/AboutMe'
import CoachingTypes from '../BusinessCoach/CoachingTypes'
import Funfacts from '../BusinessCoach/Funfacts'
import Feedback from '../BusinessCoach/Feedback'
import TopSellingCourses from '../BusinessCoach/TopSellingCourses'
import MyUpcomingEvents from '../BusinessCoach/MyUpcomingEvents'
import Blog from '../BusinessCoach/Blog'
import Subscribe from '../BusinessCoach/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'BusinessCoach',
    components: {
        Navbar,
        MainBanner,
        Features,
        AboutMe,
        CoachingTypes,
        Funfacts,
        Feedback,
        TopSellingCourses,
        MyUpcomingEvents,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>