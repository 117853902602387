<template>
    <div>
        <Navbar />
        <MainBanner />
        <LargestSelectionCourses />
        <OnlineCoaching />
        <ProjectManagementSkills />
        <Feedback />
        <TopCategories />
        <Funfacts />
        <MeetOurInstructors />
        <PotentialUnlimitedAccess />
        <Blog heading="Check Out Our Latest Blog" blogLink="/blog-two" />
        <Subscribe />
        <Partner class="ptb-70" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../OnlineTrainingSchool/MainBanner'
import LargestSelectionCourses from '../OnlineTrainingSchool/LargestSelectionCourses'
import OnlineCoaching from '../OnlineTrainingSchool/OnlineCoaching'
import ProjectManagementSkills from '../OnlineTrainingSchool/ProjectManagementSkills'
import Feedback from '../OnlineTrainingSchool/Feedback'
import TopCategories from '../Common/TopCategories'
import Funfacts from '../OnlineTrainingSchool/Funfacts'
import MeetOurInstructors from '../Common/MeetOurInstructors'
import PotentialUnlimitedAccess from '../Common/PotentialUnlimitedAccess'
import Blog from '../OnlineTrainingSchool/Blog'
import Subscribe from '../Common/Subscribe'
import Partner from '../Common/Partner'
import Footer from '../Layouts/Footer'

export default {
    name: 'OnlineTrainingSchoolPage',
    components: {
        Navbar,
        MainBanner,
        LargestSelectionCourses,
        OnlineCoaching,
        ProjectManagementSkills,
        Feedback,
        TopCategories,
        Funfacts,
        MeetOurInstructors,
        PotentialUnlimitedAccess,
        Blog,
        Subscribe,
        Partner,
        Footer,
    }
}
</script>