<template>
    <div>
        <Navbar />
        <MainBanner />
        <OurLanguageCourses />
        <WhyChooseUs />
        <Feedback />
        <TopSellingCourses />
        <Funfacts />
        <HowToApply />
        <FreeTrial />
        <OurUpcomingEvents />
        <Subscribe />
        <PartnerTwo class="bg-fe4a55" />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../LanguageSchool/MainBanner'
import OurLanguageCourses from '../LanguageSchool/OurLanguageCourses'
import WhyChooseUs from '../LanguageSchool/WhyChooseUs'
import Feedback from '../LanguageSchool/Feedback'
import TopSellingCourses from '../LanguageSchool/TopSellingCourses'
import Funfacts from '../Common/Funfacts'
import HowToApply from '../LanguageSchool/HowToApply'
import FreeTrial from '../LanguageSchool/FreeTrial'
import OurUpcomingEvents from '../LanguageSchool/OurUpcomingEvents'
import Subscribe from '../Common/Subscribe'
import PartnerTwo from '../Common/PartnerTwo'
import Footer from '../Layouts/Footer'

export default {
    name: 'LanguageSchoolPage',
    components: {
        Navbar,
        MainBanner,
        OurLanguageCourses,
        WhyChooseUs,
        Feedback,
        TopSellingCourses,
        Funfacts,
        HowToApply,
        FreeTrial,
        OurUpcomingEvents,
        Subscribe,
        PartnerTwo,
        Footer,
    }
}
</script>