<template>
    <div class="premium-access-area ptb-100">
        <div class="container">
            <div class="premium-access-content">
                <span class="sub-title">Go at your own pace</span>
                <h2>Give their limitless potential unlimited access</h2>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <router-link to="/membership-levels" class="default-btn">
                    <i class="flaticon-user"></i>
                    Give Premium Access
                    <span></span>
                </router-link>
            </div>
        </div>
        <div class="shape3"><img src="../../assets/img/shape/shape3.png" alt="image"></div>
        <div class="shape4"><img src="../../assets/img/shape/shape4.png" alt="image"></div>
        <div class="shape8"><img src="../../assets/img/shape/shape7.png" alt="image"></div>
    </div>
</template>

<script>
export default {
    name: 'PotentialUnlimitedAccess'
}
</script>