<template>
    <div>
        <Navbar />
        <MainBanner />
        <Partner class="ptb-70" />
        <CEOSlogan />
        <ProjectManagementCourses />
        <ProjectManagementSkills />
        <Funfacts />
        <ProjectManagementMasters />
        <CourseAdvisor />
        <PotentialUnlimitedAccess />
        <Testimonials />
        <LearningPaths />
        <Blog heading="Check Out Our Latest Blog" blogLink="/blog-two" />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
import Navbar from '../Layouts/Navbar'
import MainBanner from '../VendorCertificationETraining/MainBanner'
import Partner from '../Common/Partner'
import CEOSlogan from '../VendorCertificationETraining/CEOSlogan'
import ProjectManagementCourses from '../VendorCertificationETraining/ProjectManagementCourses'
import ProjectManagementSkills from '../VendorCertificationETraining/ProjectManagementSkills'
import Funfacts from '../VendorCertificationETraining/Funfacts'
import ProjectManagementMasters from '../VendorCertificationETraining/ProjectManagementMasters'
import CourseAdvisor from '../Common/CourseAdvisor'
import PotentialUnlimitedAccess from '../Common/PotentialUnlimitedAccess'
import Testimonials from '../VendorCertificationETraining/Testimonials'
import LearningPaths from  '../VendorCertificationETraining/LearningPaths'
import Blog from '../Common/Blog'
import Subscribe from '../Common/Subscribe'
import Footer from '../Layouts/Footer'

export default {
    name: 'VendorCertificationETrainingPage',
    components: {
        Navbar,
        MainBanner,
        Partner,
        CEOSlogan,
        ProjectManagementCourses,
        ProjectManagementSkills,
        Funfacts,
        ProjectManagementMasters,
        CourseAdvisor,
        PotentialUnlimitedAccess,
        Testimonials,
        LearningPaths,
        Blog,
        Subscribe,
        Footer,
    }
}
</script>